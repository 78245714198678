<template>
  <div class="feature-card-container">
    <img :alt="imageAlt" :src="imageSrc" class="feature-card-image" />
    <h5 class="feature-card-text Headline5">{{ cardTitle }}</h5>
    <span class="feature-card-text1">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureCard',
  props: {
    cardTitle: {
      type: String,
      default: 'Title',
    },
    text: {
      type: String,
      default:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.',
    },
    imageSrc: {
      type: String,
      default: '/01.svg',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.feature-card-container {
  width: 320px;
  margin: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.feature-card-image {
  width: var(--dl-size-size-doubleunit);
  height: var(--dl-size-size-doubleunit);
  object-fit: cover;
}
.feature-card-text {
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.feature-card-text1 {
  text-align: center;
}
</style>
