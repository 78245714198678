<template>
  <div class="store-banner-store-banner" v-bind:class="rootClassName">
    <img :alt="imageAlt1" :src="imageSrc1" class="store-banner-image" />
    <img :alt="imageAlt" :src="imageSrc" class="store-banner-image1" />
  </div>
</template>

<script>
export default {
  name: 'StoreBanner',
  props: {
    rootClassName: String,
    imageSrc: {
      type: String,
      default: '/app-store-badge-200h.png',
    },
    imageAlt1: {
      type: String,
      default: 'image',
    },
    imageSrc1: {
      type: String,
      default: '/google-play-badge-200h.png',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.store-banner-store-banner {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.store-banner-image {
  width: 135px;
  object-fit: cover;
}
.store-banner-image1 {
  width: 135px;
  object-fit: cover;
  margin-left: var(--dl-space-space-halfunit);
}
</style>
