<template>
  <div class="home-container">
    <div data-role="Header" class="home-header-container">
      <header class="home-header">
        <div class="home-logo">
          <img
            alt="image"
            src="/local/iumiva_logo_text-200h.png"
            class="home-image"
          />
        </div>
        <div class="home-menu">
          <span class="home-text">Blog</span>
          <span class="home-text01">Jobs</span>
          <span class="home-text02">More</span>
          <svg viewBox="0 0 1024 1024" class="home-icon">
            <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
          </svg>
        </div>
        <div class="home-container01"></div>
        <div data-role="BurgerMenu" class="home-burger-menu">
          <svg viewBox="0 0 1024 1024" class="home-menu1">
            <path
              d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"
            ></path>
            <path
              d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"
            ></path>
            <path
              d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"
            ></path>
          </svg>
        </div>
      </header>
      <div data-role="MobileMenu" class="home-mobile-menu">
        <div class="home-top">
          <div class="home-logo1">
            <img alt="image" src="/logotype-dark.svg" class="home-image1" />
          </div>
          <div data-role="CloseMobileMenu" class="home-close-menu">
            <svg viewBox="0 0 1024 1024" class="home-icon05">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="home-mid">
          <span class="home-text03">About</span>
          <span class="home-text04">Products</span>
          <span class="home-text05">Pricing</span>
          <span class="home-text06">Blog</span>
          <span class="home-text07">Jobs</span>
          <span class="home-text08">More</span>
        </div>
        <div class="home-bot">
          <div class="home-container02">
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link"
            >
              <div class="home-container03">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon07">
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://dribbble.com"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link1"
            >
              <div class="home-container04">
                <svg viewBox="0 0 877.7142857142857 1024" class="home-icon09">
                  <path
                    d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link2"
            >
              <div class="home-container05">
                <svg viewBox="0 0 950.8571428571428 1024" class="home-icon11">
                  <path
                    d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noreferrer noopener"
              class="home-link3"
            >
              <div class="home-container06">
                <svg viewBox="0 0 1024 1024" class="home-icon13">
                  <path
                    d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="home-main">
      <div class="home-blur-background"></div>
      <div class="home-hero">
        <div class="home-container07">
          <h1 class="home-text09">
            <span>We show you what you feel</span>
            <br />
          </h1>
          <h3 class="home-text12">
            <span class="home-text13">
              Take notes.
              <span v-html="rawdxqs"></span>
            </span>
            <span class="home-text14">See your emotions</span>
            <span class="home-text15">
              and feelings in numbers and charts. Monitor your progress during the
              time.
              <span v-html="rawfzul"></span>
            </span>
            <span class="home-text16">Make better decisions</span>
            <span class="home-text17">. Live better life.</span>
          </h3>
          <div class="home-container08">
            <a href="https://app.iumiva.com/" class="home-link4">
              <primary-button
                button="Get Started"
                rootClassName="primary-button-root-class-name"
                class="home-component"
              ></primary-button>
            </a>
          </div>
        </div>
        <div class="home-container09">
          <a
            href="https://app.iumiva.com/"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link5"
          >
            <img
              alt="image"
              src="/local/phone_screen_preview-400w.png"
              class="home-image2"
            />
          </a>
        </div>
      </div>
      <img
        alt="image"
        src="/turquoise-circle.svg"
        class="home-turquoise-cirble"
      />
      <img alt="image" src="/purple-circle.svg" class="home-purple-circle" />
      <img alt="image" src="/left.svg" class="home-left" />
      <img alt="image" src="/right.svg" class="home-right" />
    </div>
    <div class="home-features">
      <h2 class="home-text18">Helpful Features</h2>
      <span class="home-text19">
        The unique features of our app will help you keep a diary and track your
        progress towards your goals.
      </span>
      <div class="home-features1">
        <feature-card
          imageSrc="/03.svg"
          cardTitle="Emotion Analysis"
          text="Each note is analyzed, allowing you to see the full spectrum of your emotions."
        ></feature-card>
        <feature-card
          imageSrc="/02.svg"
          cardTitle="Emotion Trends"
          text="See how you felt last week or even a month ago. Watch your mood improve over time."
        ></feature-card>
        <feature-card
          imageSrc="/04.svg"
          cardTitle="Reminders"
          text="Daily notifications will help you maintain your journaling habit at the same time each day."
        ></feature-card>
        <feature-card
          imageSrc="/05.svg"
          cardTitle="Personalized Tips"
          text="Receive personalized advice to help you achieve your goals."
        ></feature-card>
        <feature-card
          imageSrc="/07.svg"
          cardTitle="Calendar"
          text="View the entire history of your diary entries for any given day."
        ></feature-card>
      </div>
    </div>
    <div class="home-testimonials">
      <div class="home-container10">
        <div class="home-container11">
          <img alt="image" src="/quote-mark.svg" class="home-image3" />
          <h1 class="home-text20">Real Stories of Improved Quality of Life</h1>
          <div class="home-container12">
            <testimonials-card
              text="After a few weeks of using IUMIVA, I've become much more aware of my emotional patterns. The recommendations are spot on and have genuinely helped me feel more in control of my moods."
              text2="Teacher"
              text1="Olivia"
            ></testimonials-card>
          </div>
        </div>
        <div class="home-container13">
          <div class="home-container14">
            <testimonials-card
              src="/logo-1.svg"
              text="I've been using this app to jot down my daily thoughts for a month now, and I've noticed a significant improvement in my emotional well-being. It's amazing how tracking my emotions helps me better manage my stress and stay positive."
              text1="Sophia"
              text2="Marketing Manager"
              imageSrc="/logo-1.svg"
            ></testimonials-card>
          </div>
          <div class="home-container15">
            <testimonials-card
              text="I've been using this app for six weeks, and it's made a huge difference in my emotional health. The personalized recommendations have helped me find new ways to stay positive and grounded."
              text1="Mike"
              text2="IT Consultant"
              imageSrc="/logo-2.svg"
            ></testimonials-card>
          </div>
        </div>
      </div>
    </div>
    <div class="home-feature1">
      <div class="home-container16">
        <img src="/local/img_0614-500w.png" alt="image" class="home-image4" />
      </div>
      <div class="home-container17">
        <h2>Try it now</h2>
        <span class="home-text22">
          Track your emotions, see your progress, and receive personalized tips to
          help you achieve your goals. Download now and start your journey to a
          happier, healthier you!
        </span>
        <a href="https://app.iumiva.com" class="home-link6">
          <primary-button
            button="Get Started"
            rootClassName="primary-button-root-class-name1"
            class="home-component09"
          ></primary-button>
        </a>
      </div>
    </div>
    <div class="home-footer">
      <footer class="home-container18">
        <div class="home-container19">
          <a
            href="https://app.iumiva.com/django_static/documents/terms_of_use.pdf"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link7"
          >
            Terms of use
          </a>
          <a
            href="https://app.iumiva.com/django_static/documents/privacy_policy.pdf"
            target="_blank"
            rel="noreferrer noopener"
            class="home-link8"
          >
            Privacy Policy
          </a>
        </div>
        <div class="home-divider"></div>
        <div class="home-container20">
          <span class="home-text23 Body2">
            © 2024 iumiva. All rights reserved
          </span>
        </div>
      </footer>
    </div>
    <div class="home-cta"></div>
  </div>
</template>

<script>
import PrimaryButton from '../components/primary-button'
import FeatureCard from '../components/feature-card'
import TestimonialsCard from '../components/testimonials-card'

export default {
  name: 'Home',
  props: {},
  components: {
    PrimaryButton,
    FeatureCard,
    TestimonialsCard,
  },
  data() {
    return {
      rawdxqs: ' ',
      rawfzul: ' ',
    }
  },
  metaInfo: {
    title: 'IUMIVA, We show you what you feel',
    meta: [
      {
        property: 'og:title',
        content: 'IUMIVA, We show you what you feel',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image {
  width: 151px;
  height: 48px;
  object-fit: cover;
}
.home-menu {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-text {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text01 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.home-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text02 {
  transition: 0.3s;
}
.home-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-container01 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-menu1 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.home-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.home-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.home-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-image1 {
  width: 100px;
  object-fit: cover;
}
.home-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-icon05 {
  width: 24px;
  height: 24px;
}
.home-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text05 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text06 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text06:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text07 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text07:hover {
  color: var(--dl-color-turquoise-default);
}
.home-text08 {
  transition: 0.3s;
}
.home-text08:hover {
  color: var(--dl-color-turquoise-default);
}
.home-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.home-container02 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-link {
  display: contents;
}
.home-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon07 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon07:hover {
  fill: var(--dl-color-purple-default);
}
.home-link1 {
  display: contents;
}
.home-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon09 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon09:hover {
  fill: var(--dl-color-pink-default);
}
.home-link2 {
  display: contents;
}
.home-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon11:hover {
  fill: var(--dl-color-turquoise-default);
}
.home-link3 {
  display: contents;
}
.home-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.home-icon13 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.home-icon13:hover {
  fill: var(--dl-color-orange-default);
}
.home-main {
  width: 100%;
  height: 750px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.home-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  border-color: var(--dl-color-gray-900);
  border-width: 0px;
  flex-direction: row;
  justify-content: space-between;
}
.home-container07 {
  width: 578px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-text09 {
  width: 667px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-text12 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.home-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link4 {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-container09 {
  width: 373px;
  height: 630px;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-link5 {
  display: contents;
}
.home-image2 {
  width: 301px;
  height: 618px;
  align-self: center;
  object-fit: cover;
  text-decoration: none;
}
.home-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.home-purple-circle {
  top: 0px;
  left: auto;
  right: 80px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.home-left {
  top: 553px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-right {
  top: 341px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text19 {
  max-width: 500px;
  text-align: center;
}
.home-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.home-container10 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container11 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-image3 {
  top: -22px;
  left: -65px;
  right: auto;
  width: 100px;
  bottom: auto;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.home-text20 {
  z-index: 100;
  max-width: 500px;
}
.home-container12 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-container13 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.home-container14 {
  width: 350px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container15 {
  width: 300px;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
.home-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-container16 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-purple-100);
}
.home-image4 {
  width: 330px;
  height: 714px;
  object-fit: cover;
}
.home-container17 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text22 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.home-link6 {
  display: contents;
}
.home-component09 {
  text-decoration: none;
}
.home-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.home-container18 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-container19 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.home-link7 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link7:hover {
  color: var(--dl-color-turquoise-default);
}
.home-link8 {
  transition: 0.3s;
  white-space: nowrap;
  text-decoration: none;
}
.home-link8:hover {
  color: var(--dl-color-turquoise-default);
}
.home-divider {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.home-container20 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-text23 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.home-cta {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: none;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-turquoise-default);
}
@media(max-width: 991px) {
  .home-container01 {
    display: none;
  }
  .home-main {
    height: auto;
  }
  .home-hero {
    height: auto;
    flex-direction: column;
  }
  .home-text09 {
    text-align: center;
  }
  .home-container08 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container10 {
    flex-direction: column;
  }
  .home-container12 {
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .home-container13 {
    align-items: center;
    margin-left: 0px;
  }
  .home-container15 {
    width: 350px;
  }
  .home-feature1 {
    flex-direction: column;
  }
  .home-image4 {
    width: 406px;
    height: 878px;
  }
  .home-container17 {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media(max-width: 767px) {
  .home-menu {
    display: none;
  }
  .home-burger-menu {
    display: block;
  }
  .home-text09 {
    font-size: 50px;
    text-align: center;
  }
  .home-text18 {
    font-size: 40px;
  }
  .home-container11 {
    align-items: center;
  }
  .home-image3 {
    top: -2px;
    left: 21px;
    right: auto;
    width: 50px;
    bottom: auto;
  }
  .home-text20 {
    font-size: 40px;
    text-align: center;
  }
  .home-image4 {
    width: 427px;
    height: 924px;
  }
  .home-container19 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .home-container20 {
    flex-direction: column;
  }
  .home-text23 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .home-container01 {
    display: none;
  }
  .home-menu1 {
    display: none;
  }
  .home-bot {
    width: 100%;
  }
  .home-container07 {
    width: 430px;
  }
  .home-text09 {
    width: 440px;
    height: 131px;
    font-size: 50px;
    align-self: center;
    margin-top: var(--dl-space-space-doubleunit);
    text-align: center;
    line-height: 1;
  }
  .home-text12 {
    width: 373px;
    align-self: center;
    text-align: center;
    font-weight: 400;
  }
  .home-text13 {
    font-style: normal;
  }
  .home-text14 {
    font-style: inherit;
    font-weight: 700;
  }
  .home-text15 {
    font-style: normal;
  }
  .home-text16 {
    font-weight: 700;
  }
  .home-text17 {
    font-style: normal;
  }
  .home-container09 {
    width: 430px;
  }
  .home-text18 {
    text-align: center;
  }
  .home-text19 {
    text-align: center;
  }
  .home-text20 {
    font-size: 40px;
    max-width: 400px;
    text-transform: capitalize;
  }
  .home-image4 {
    width: 326px;
    height: 887px;
  }
}
</style>
