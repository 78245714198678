<template>
  <div class="original-container">
    <div data-role="Header" class="original-header-container">
      <header class="original-header">
        <div class="original-logo">
          <img
            alt="image"
            src="/local/iumiva_logo_text-200h.png"
            class="original-image"
          />
        </div>
        <div class="original-menu">
          <span class="original-text">Blog</span>
          <span class="original-text01">Jobs</span>
          <span class="original-text02">More</span>
          <svg viewBox="0 0 1024 1024" class="original-icon">
            <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
          </svg>
        </div>
        <div class="original-container01"><store-banner></store-banner></div>
        <div data-role="BurgerMenu" class="original-burger-menu">
          <svg viewBox="0 0 1024 1024" class="original-menu1">
            <path
              d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"
            ></path>
            <path
              d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"
            ></path>
            <path
              d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"
            ></path>
          </svg>
        </div>
      </header>
      <div data-role="MobileMenu" class="original-mobile-menu">
        <div class="original-top">
          <div class="original-logo1">
            <img alt="image" src="/logotype-dark.svg" class="original-image01" />
          </div>
          <div data-role="CloseMobileMenu" class="original-close-menu">
            <svg viewBox="0 0 1024 1024" class="original-icon05">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="original-mid">
          <span class="original-text03">About</span>
          <span class="original-text04">Products</span>
          <span class="original-text05">Pricing</span>
          <span class="original-text06">Blog</span>
          <span class="original-text07">Jobs</span>
          <span class="original-text08">More</span>
        </div>
        <div class="original-bot">
          <div class="original-container02">
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link"
            >
              <div class="original-container03">
                <svg viewBox="0 0 877.7142857142857 1024" class="original-icon07">
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://dribbble.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link1"
            >
              <div class="original-container04">
                <svg viewBox="0 0 877.7142857142857 1024" class="original-icon09">
                  <path
                    d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link2"
            >
              <div class="original-container05">
                <svg viewBox="0 0 950.8571428571428 1024" class="original-icon11">
                  <path
                    d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link3"
            >
              <div class="original-container06">
                <svg viewBox="0 0 1024 1024" class="original-icon13">
                  <path
                    d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="original-main">
      <div class="original-blur-background"></div>
      <div class="original-hero">
        <div class="original-container07">
          <h1 class="original-text09">
            <span>We show you what you feel</span>
            <br />
          </h1>
          <h3 class="original-text12">
            <span>Take notes. Get them analyzed in numbers and charts. </span>
            <span>See progress. Make better decisions.</span>
          </h3>
          <div class="original-container08">
            <primary-button
              button="Get Started"
              class="original-component01"
            ></primary-button>
          </div>
        </div>
        <a
          href="https://app.iumiva.com/"
          target="_blank"
          rel="noreferrer noopener"
          class="original-link4"
        >
          <img
            alt="image"
            src="/local/phone_screen_preview-400w.png"
            class="original-image02"
          />
        </a>
      </div>
      <img
        alt="image"
        src="/turquoise-circle.svg"
        class="original-turquoise-cirble"
      />
      <img alt="image" src="/purple-circle.svg" class="original-purple-circle" />
      <img alt="image" src="/left.svg" class="original-left" />
      <img alt="image" src="/right.svg" class="original-right" />
    </div>
    <div class="original-clients">
      <div class="original-divider"></div>
      <img alt="image" src="/logo-1.svg" class="original-image03" />
      <img alt="image" src="/logo-4.svg" class="original-image04" />
      <img alt="image" src="/logo-3.svg" class="original-image05" />
      <img alt="image" src="/logo-5.svg" class="original-image06" />
      <img alt="image" src="/logo-6.svg" class="original-image07" />
      <div class="original-divider1"></div>
    </div>
    <div class="original-features">
      <h2 class="Headline2 original-text15">Tailor-made features</h2>
      <span class="original-text16">
        <span class="original-text17">
          Lorem ipsum is common placeholder text used to demonstrate the graphic
          elements of a document or visual presentation.
        </span>
      </span>
      <div class="original-features1">
        <feature-card cardTitle="Robust workflow"></feature-card>
        <feature-card imageSrc="/02.svg" cardTitle="Flexibility"></feature-card>
        <feature-card imageSrc="/03.svg" cardTitle="User friendly"></feature-card>
        <feature-card
          imageSrc="/04.svg"
          cardTitle="Multiple layouts"
        ></feature-card>
        <feature-card
          imageSrc="/05.svg"
          cardTitle="Better compoents"
        ></feature-card>
        <feature-card
          imageSrc="/06.svg"
          cardTitle="Well organized"
        ></feature-card>
      </div>
    </div>
    <div class="original-testimonials">
      <div class="original-container09">
        <div class="original-container10">
          <img alt="image" src="/quote-mark.svg" class="original-image08" />
          <h1 class="original-text18 Headline2">
            Real Stories from Real Customers
          </h1>
          <span>Get inspired by these stories.</span>
          <div class="original-container11">
            <testimonials-card imageSrc="/logo-4.svg"></testimonials-card>
          </div>
        </div>
        <div class="original-container12">
          <div class="original-container13">
            <testimonials-card
              src="/logo-1.svg"
              text="I used Landify and created a landing page for my startup within a week. The Landify UI Kit is simple and highly intuitive, so anyone can use it."
              text1="Jane Cooper"
              text2="CEO, Airbnb"
              imageSrc="/logo-1.svg"
            ></testimonials-card>
          </div>
          <div class="original-container14">
            <testimonials-card
              text="Landify saved our time in designing my company page."
              text1="Kristin Watson"
              text2="Co-Founder, FedEx"
              imageSrc="/logo-2.svg"
            ></testimonials-card>
          </div>
        </div>
      </div>
    </div>
    <div class="original-achievements">
      <div class="original-container15">
        <h2 class="original-text20">
          <span class="original-text21">
            Our 18 years of
            <span v-html="rawm1vt"></span>
          </span>
          <br />
          <span>achievements</span>
        </h2>
        <span class="original-text24">
          With our super powers we have reached this
        </span>
      </div>
      <div class="original-container16">
        <div class="original-container17">
          <stats-card
            number="10,000+"
            imageSrc="/01.svg"
            description="Downloads per day"
          ></stats-card>
          <stats-card
            number="2 Milion"
            imageSrc="/04.svg"
            description="Users"
          ></stats-card>
        </div>
        <div class="original-container18">
          <stats-card number="500+" description="Clients"></stats-card>
          <stats-card
            number="140"
            imageSrc="/07.svg"
            description="Countries"
          ></stats-card>
        </div>
      </div>
    </div>
    <div class="original-feature1">
      <div class="original-container19">
        <img
          alt="image"
          src="/iphonex-1200w.png"
          image_src="/iphonex-1200w.png"
          class="original-image09"
        />
      </div>
      <div class="original-container20">
        <h3 class="Headline3">Headline</h3>
        <span class="original-text26 Lead1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum
          diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem
          egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non
          commodo volutpat, pharetra, vel.
        </span>
        <link-icon-button text="Get Started"></link-icon-button>
      </div>
    </div>
    <div class="original-feature2">
      <div class="original-container21">
        <h2 class="Headline2 original-text27">
          Enter the world of all fashion trends
        </h2>
        <span class="original-text28 Lead1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum
          diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem
          egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non
          commodo volutpat, pharetra, vel.
        </span>
      </div>
      <img
        alt="image"
        src="https://images.unsplash.com/photo-1535157412991-2ef801c1748b?ixlib=rb-1.2.1&amp;q=85&amp;fm=jpg&amp;crop=entropy&amp;cs=srgb&amp;h=1000"
        image_src="/iphonex-1200w.png"
        class="original-image10"
      />
    </div>
    <div class="original-cta">
      <div class="original-container22">
        <div class="original-container23">
          <h2 class="Headline2 original-text29">
            Manage your projects from your mobile
          </h2>
          <span class="original-text30 Lead1">
            Download the app to manage your projects, keep track of the progress
            and complete tasks without procastinating. Stay on track and complete
            on time!
          </span>
          <span class="original-text31 Subtitle2">
            <span class="original-text32">Get the App</span>
          </span>
          <store-banner></store-banner>
        </div>
        <div class="original-container24">
          <img
            alt="image"
            src="/iphonex-1200w.png"
            image_src="/iphonex-1200w.png"
            class="original-image11"
          />
          <img
            alt="image"
            src="/iphonex-1200w.png"
            image_src="/iphonex-1200w.png"
            class="original-image12"
          />
        </div>
      </div>
    </div>
    <div class="original-footer">
      <footer class="original-container25">
        <img alt="image" src="/logotype-light.svg" class="original-image13" />
        <div class="original-container26">
          <span class="original-text33">About</span>
          <span class="original-text34">Features</span>
          <span class="original-text35">Pricing</span>
          <span class="original-text36">Careers</span>
          <span class="original-text37">Help</span>
          <span class="original-text38">Privacy Policy</span>
        </div>
        <div class="original-divider2"></div>
        <div class="original-container27">
          <span class="original-text39 Body2">
            © 2020 Landify UI Kit. All rights reserved
          </span>
          <div class="original-container28">
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link5"
            >
              <div class="original-container29">
                <svg viewBox="0 0 877.7142857142857 1024" class="original-icon15">
                  <path
                    d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://dribbble.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link6"
            >
              <div class="original-container30">
                <svg viewBox="0 0 877.7142857142857 1024" class="original-icon17">
                  <path
                    d="M585.143 857.143c-5.714-33.143-27.429-147.429-80-284.571-0.571 0-1.714 0.571-2.286 0.571 0 0-222.286 77.714-294.286 234.286-3.429-2.857-8.571-6.286-8.571-6.286 65.143 53.143 148 85.714 238.857 85.714 52 0 101.143-10.857 146.286-29.714zM479.429 510.286c-9.143-21.143-19.429-42.286-30.286-63.429-193.143 57.714-378.286 53.143-384.571 53.143-0.571 4-0.571 8-0.571 12 0 96 36.571 184 96 250.286v0c102.286-182.286 304.571-247.429 304.571-247.429 5.143-1.714 10.286-2.857 14.857-4.571zM418.286 389.143c-65.143-115.429-134.286-209.143-139.429-216-104.571 49.143-182.286 145.714-206.857 261.714 9.714 0 166.286 1.714 346.286-45.714zM809.143 571.429c-8-2.286-112.571-35.429-233.714-16.571 49.143 135.429 69.143 245.714 73.143 268 84-56.571 143.429-146.857 160.571-251.429zM349.143 148c-0.571 0-0.571 0-1.143 0.571 0 0 0.571-0.571 1.143-0.571zM686.286 230.857c-65.714-58.286-152.571-93.714-247.429-93.714-30.286 0-60 4-88.571 10.857 5.714 7.429 76.571 100.571 140.571 218.286 141.143-52.571 194.286-133.714 195.429-135.429zM813.714 508c-1.143-88.571-32.571-170.286-85.143-234.286-1.143 1.143-61.143 88-209.143 148.571 8.571 17.714 17.143 36 25.143 54.286 2.857 6.286 5.143 13.143 8 19.429 129.143-16.571 256.571 11.429 261.143 12zM877.714 512c0 242.286-196.571 438.857-438.857 438.857s-438.857-196.571-438.857-438.857 196.571-438.857 438.857-438.857 438.857 196.571 438.857 438.857z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link7"
            >
              <div class="original-container31">
                <svg viewBox="0 0 950.8571428571428 1024" class="original-icon19">
                  <path
                    d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                  ></path>
                </svg>
              </div>
            </a>
            <a
              href="https://youtube.com"
              target="_blank"
              rel="noreferrer noopener"
              class="original-link8"
            >
              <div class="original-container32">
                <svg viewBox="0 0 1024 1024" class="original-icon21">
                  <path
                    d="M406.286 644.571l276.571-142.857-276.571-144.571v287.429zM512 152c215.429 0 358.286 10.286 358.286 10.286 20 2.286 64 2.286 102.857 43.429 0 0 31.429 30.857 40.571 101.714 10.857 82.857 10.286 165.714 10.286 165.714v77.714s0.571 82.857-10.286 165.714c-9.143 70.286-40.571 101.714-40.571 101.714-38.857 40.571-82.857 40.571-102.857 42.857 0 0-142.857 10.857-358.286 10.857v0c-266.286-2.286-348-10.286-348-10.286-22.857-4-74.286-2.857-113.143-43.429 0 0-31.429-31.429-40.571-101.714-10.857-82.857-10.286-165.714-10.286-165.714v-77.714s-0.571-82.857 10.286-165.714c9.143-70.857 40.571-101.714 40.571-101.714 38.857-41.143 82.857-41.143 102.857-43.429 0 0 142.857-10.286 358.286-10.286v0z"
                  ></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
      </footer>
    </div>
    <img
      alt="pastedImage"
      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/70cfe112-98da-4337-8d97-727973e90fdf/f123bad1-30d7-411e-bdcc-ce44244fe2d1?org_if_sml=1805996&amp;force_format=original"
    />
    <img
      alt="pastedImage"
      src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/70cfe112-98da-4337-8d97-727973e90fdf/8e200073-e07d-4fe6-bd94-aacd0e72b704?org_if_sml=1805996&amp;force_format=original"
    />
  </div>
</template>

<script>
import StoreBanner from '../components/store-banner'
import PrimaryButton from '../components/primary-button'
import FeatureCard from '../components/feature-card'
import TestimonialsCard from '../components/testimonials-card'
import StatsCard from '../components/stats-card'
import LinkIconButton from '../components/link-icon-button'

export default {
  name: 'Original',
  props: {},
  components: {
    StoreBanner,
    PrimaryButton,
    FeatureCard,
    TestimonialsCard,
    StatsCard,
    LinkIconButton,
  },
  data() {
    return {
      rawm1vt: ' ',
    }
  },
  metaInfo: {
    title: 'original - exported project',
    meta: [
      {
        property: 'og:title',
        content: 'original - exported project',
      },
    ],
  },
}
</script>

<style scoped>
.original-container {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.original-header-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.original-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: relative;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-logo {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-image {
  width: 151px;
  height: 48px;
  object-fit: cover;
}
.original-menu {
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.original-text {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.original-text:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text01 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-doubleunit);
}
.original-text01:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text02 {
  transition: 0.3s;
}
.original-text02:hover {
  color: var(--dl-color-turquoise-default);
}
.original-icon {
  width: 24px;
  height: 24px;
}
.original-container01 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-menu1 {
  width: 24px;
  height: 24px;
  margin-left: var(--dl-space-space-unit);
}
.original-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 101;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.original-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
  justify-content: space-between;
}
.original-logo1 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-image01 {
  width: 100px;
  object-fit: cover;
}
.original-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.original-icon05 {
  width: 24px;
  height: 24px;
}
.original-mid {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.original-text03 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.original-text03:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text04 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.original-text04:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text05 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.original-text05:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text06 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.original-text06:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text07 {
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.original-text07:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text08 {
  transition: 0.3s;
}
.original-text08:hover {
  color: var(--dl-color-turquoise-default);
}
.original-bot {
  flex: 0 0 auto;
  display: flex;
  margin-top: auto;
  align-items: flex-start;
  flex-direction: column;
}
.original-container02 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.original-link {
  display: contents;
}
.original-container03 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon07 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon07:hover {
  fill: var(--dl-color-purple-default);
}
.original-link1 {
  display: contents;
}
.original-container04 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon09 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon09:hover {
  fill: var(--dl-color-pink-default);
}
.original-link2 {
  display: contents;
}
.original-container05 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon11:hover {
  fill: var(--dl-color-turquoise-default);
}
.original-link3 {
  display: contents;
}
.original-container06 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon13 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon13:hover {
  fill: var(--dl-color-orange-default);
}
.original-main {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.original-blur-background {
  top: 0px;
  left: auto;
  right: 0px;
  width: 100%;
  bottom: auto;
  filter: blur(60px);
  height: 70vh;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  background-color: #f2f2f2;
}
.original-hero {
  flex: 0 0 auto;
  width: 100%;
  height: 80vh;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-container07 {
  width: 578px;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.original-text09 {
  width: 667px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.original-text12 {
  padding-bottom: var(--dl-space-space-doubleunit);
}
.original-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.original-component01 {
  text-decoration: none;
}
.original-link4 {
  display: contents;
}
.original-image02 {
  width: 301px;
  height: 618px;
  object-fit: cover;
  text-decoration: none;
}
.original-turquoise-cirble {
  top: 439px;
  left: auto;
  right: 430px;
  width: 170px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.6;
  position: absolute;
  object-fit: cover;
}
.original-purple-circle {
  top: -100px;
  left: auto;
  right: -26px;
  width: 400px;
  bottom: auto;
  filter: blur(60px);
  opacity: 0.4;
  position: absolute;
  object-fit: cover;
}
.original-left {
  top: 653px;
  left: 0px;
  right: auto;
  width: 420px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.original-right {
  top: 441px;
  left: auto;
  right: 0px;
  width: 612px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.original-clients {
  width: 100%;
  height: 329px;
  display: flex;
  z-index: 100;
  overflow: hidden;
  position: relative;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  padding-top: var(--dl-space-space-doubleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-doubleunit);
  justify-content: space-between;
}
.original-divider {
  top: 0px;
  left: auto;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.original-image03 {
  width: 100px;
  object-fit: cover;
}
.original-image04 {
  width: 100px;
  object-fit: cover;
}
.original-image05 {
  width: 100px;
  object-fit: cover;
}
.original-image06 {
  width: 100px;
  object-fit: cover;
}
.original-image07 {
  width: 100px;
  object-fit: cover;
}
.original-divider1 {
  top: auto;
  left: auto;
  right: auto;
  width: 100%;
  bottom: 0px;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg, rgba(192, 204, 218, 0.1) 0%, rgba(192, 204, 218, 0.6) 50.38%, rgba(192, 204, 218, 0.1) 100%);;
}
.original-features {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.original-text16 {
  max-width: 500px;
  text-align: center;
}
.original-text17 {
  white-space: pre-wrap;
}
.original-features1 {
  display: flex;
  flex-wrap: wrap;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.original-testimonials {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: center;
  background-color: var(--dl-color-turquoise-100);
}
.original-container09 {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.original-container10 {
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.original-image08 {
  top: -22px;
  left: -65px;
  right: auto;
  width: 100px;
  bottom: auto;
  z-index: 10;
  position: absolute;
  object-fit: cover;
}
.original-text18 {
  z-index: 100;
  max-width: 500px;
}
.original-container11 {
  flex: 0 0 auto;
  width: 350px;
  display: flex;
  align-self: flex-end;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.original-container12 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-doubleunit);
  flex-direction: column;
}
.original-container13 {
  width: 350px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.original-container14 {
  width: 300px;
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: flex-start;
  flex-direction: column;
}
.original-achievements {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.original-container15 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.original-text21 {
  white-space: pre-wrap;
}
.original-container16 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.original-container17 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.original-container18 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.original-feature1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.original-container19 {
  flex: 0 0 auto;
  width: 540px;
  height: 540px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-purple-100);
}
.original-image09 {
  width: 360px;
  margin-top: 100px;
  object-fit: cover;
}
.original-container20 {
  flex: 1;
  margin: var(--dl-space-space-doubleunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.original-text26 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.original-feature2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.original-container21 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.original-text28 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-doubleunit);
}
.original-image10 {
  width: 100%;
  height: 400px;
  margin-top: var(--dl-space-space-tripleunit);
  object-fit: cover;
}
.original-cta {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-turquoise-default);
}
.original-container22 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.original-container23 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.original-text30 {
  max-width: 500px;
}
.original-text31 {
  max-width: 400px;
  margin-top: var(--dl-space-space-doubleunit);
  margin-bottom: var(--dl-space-space-halfunit);
}
.original-text32 {
  white-space: pre-wrap;
}
.original-container24 {
  width: 520px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.original-image11 {
  top: -100px;
  left: auto;
  right: var(--dl-space-space-doubleunit);
  width: 232px;
  bottom: auto;
  position: absolute;
  object-fit: cover;
}
.original-image12 {
  top: auto;
  left: var(--dl-space-space-doubleunit);
  right: auto;
  width: 232px;
  bottom: -100px;
  position: absolute;
  object-fit: cover;
}
.original-footer {
  flex: 0 0 auto;
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.original-container25 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.original-image13 {
  object-fit: cover;
}
.original-container26 {
  display: flex;
  margin-top: var(--dl-space-space-doubleunit);
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: flex-start;
}
.original-text33 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.original-text33:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text34 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.original-text34:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text35 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.original-text35:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text36 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.original-text36:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text37 {
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}
.original-text37:hover {
  color: var(--dl-color-turquoise-default);
}
.original-text38 {
  transition: 0.3s;
  white-space: nowrap;
}
.original-text38:hover {
  color: var(--dl-color-turquoise-default);
}
.original-divider2 {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--dl-color-gray-white);
}
.original-container27 {
  width: 100%;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.original-text39 {
  color: var(--dl-color-gray-300);
  margin-top: var(--dl-space-space-unit);
}
.original-container28 {
  flex: 0 0 auto;
  width: 200px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.original-link5 {
  display: contents;
}
.original-container29 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon15 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon15:hover {
  fill: var(--dl-color-purple-default);
}
.original-link6 {
  display: contents;
}
.original-container30 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon17 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon17:hover {
  fill: var(--dl-color-pink-default);
}
.original-link7 {
  display: contents;
}
.original-container31 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon19 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon19:hover {
  fill: var(--dl-color-turquoise-default);
}
.original-link8 {
  display: contents;
}
.original-container32 {
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-round);
  flex-direction: column;
  background-color: var(--dl-color-gray-900);
}
.original-icon21 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-unit);
  height: var(--dl-size-size-unit);
  transition: 0.3s;
}
.original-icon21:hover {
  fill: var(--dl-color-orange-default);
}
@media(max-width: 991px) {
  .original-container01 {
    display: none;
  }
  .original-hero {
    height: auto;
    flex-direction: column;
  }
  .original-text09 {
    text-align: center;
  }
  .original-container08 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .original-container09 {
    flex-direction: column;
  }
  .original-container11 {
    align-self: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .original-container12 {
    align-items: center;
    margin-left: 0px;
  }
  .original-container14 {
    width: 350px;
  }
  .original-achievements {
    flex-direction: column;
  }
  .original-container16 {
    margin-top: var(--dl-space-space-doubleunit);
  }
  .original-feature1 {
    flex-direction: column;
  }
  .original-container20 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .original-container21 {
    align-items: flex-start;
    flex-direction: column;
  }
  .original-container24 {
    display: none;
  }
}
@media(max-width: 767px) {
  .original-menu {
    display: none;
  }
  .original-burger-menu {
    display: block;
  }
  .original-text09 {
    font-size: 50px;
    text-align: center;
  }
  .original-clients {
    justify-content: center;
  }
  .original-image03 {
    margin: var(--dl-space-space-unit);
  }
  .original-image04 {
    margin: var(--dl-space-space-unit);
  }
  .original-image05 {
    margin: var(--dl-space-space-unit);
  }
  .original-image06 {
    margin: var(--dl-space-space-unit);
  }
  .original-image07 {
    margin: var(--dl-space-space-unit);
  }
  .original-text15 {
    font-size: 40px;
  }
  .original-container10 {
    align-items: center;
  }
  .original-image08 {
    top: -2px;
    left: 21px;
    right: auto;
    width: 50px;
    bottom: auto;
  }
  .original-text18 {
    font-size: 40px;
    text-align: center;
  }
  .original-text20 {
    font-size: 40px;
  }
  .original-text27 {
    font-size: 40px;
  }
  .original-text29 {
    font-size: 40px;
  }
  .original-container26 {
    flex-wrap: wrap;
    justify-content: center;
  }
  .original-container27 {
    flex-direction: column;
  }
  .original-text39 {
    margin-top: var(--dl-space-space-unit);
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .original-container01 {
    display: none;
  }
  .original-bot {
    width: 100%;
  }
  .original-container07 {
    width: 430px;
  }
  .original-text09 {
    width: 440px;
    height: 158px;
  }
  .original-text15 {
    text-align: center;
  }
  .original-text16 {
    text-align: center;
  }
  .original-container15 {
    align-items: center;
  }
  .original-text20 {
    text-align: center;
  }
  .original-text24 {
    text-align: center;
  }
  .original-container17 {
    flex-direction: column;
  }
  .original-container18 {
    flex-direction: column;
  }
}
</style>
