<template>
  <div class="primary-button-container" v-bind:class="rootClassName">
    <button class="primary-button-button MediumLabel button">{{ button }}</button>
  </div>
</template>

<script>
export default {
  name: 'PrimaryButton',
  props: {
    button: {
      type: String,
      default: 'Button',
    },
    rootClassName: String,
  },
}
</script>

<style scoped>
.primary-button-container {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.primary-button-button {
  color: var(--dl-color-gray-white);
  align-self: center;
  transition: 0.3s;
  padding-top: 12px;
  border-width: 0px;
  padding-left: 32px;
  border-radius: var(--dl-radius-radius-radius6);
  padding-right: 32px;
  padding-bottom: 12px;
  background-color: var(--dl-color-purple-default);
}
.primary-button-button:hover {
  background-color: var(--dl-color-purple-900);
}
.primary-button-button:active {
  background-color: var(--dl-color-purple-1000);
}


@media(max-width: 479px) {
  .primary-button-button {
    width: 175px;
    height: 55px;
  }
  .primary-button-root-class-name {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
  }
  .primary-button-root-class-name1 {
    fill: var(--dl-color-gray-900);
    color: var(--dl-color-gray-900);
    align-self: center;
  }
}
</style>
