<template>
  <div class="testimonials-card-container">
    <div class="testimonials-card-container1">
      <img :alt="imageAlt1" :src="imageSrc1" class="testimonials-card-image" />
      <div class="testimonials-card-container2">
        <span class="testimonials-card-text Lead1">{{ text }}</span>
        <span class="Subtitle1">{{ text1 }}</span>
        <span class="testimonials-card-text2 Lead2">{{ text2 }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TestimonialsCard',
  props: {
    imageAlt1: {
      type: String,
      default: 'image',
    },
    imageAlt: {
      type: String,
      default: 'image',
    },
    text2: {
      type: String,
      default: 'Vice President, GoPro',
    },
    text1: {
      type: String,
      default: 'Floyd Miles',
    },
    text: {
      type: String,
      default:
        'To quickly start my startup landing page design, I was looking for a landing page UI Kit. Landify is one of the best landing page UI kit I have come across. It’s so flexible, well organised and easily editable.',
    },
    imageSrc: {
      type: String,
      default:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Airbnb_Logo_B%C3%A9lo.svg/2560px-Airbnb_Logo_B%C3%A9lo.svg.png',
    },
    imageSrc1: {
      type: String,
      default: '/quote-mark.svg',
    },
  },
}
</script>

<style scoped>
.testimonials-card-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.testimonials-card-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.testimonials-card-image {
  top: 81px;
  left: 46px;
  right: auto;
  width: 15px;
  bottom: auto;
  object-fit: cover;
}
.testimonials-card-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.testimonials-card-text {
  margin-bottom: var(--dl-space-space-doubleunit);
}
.testimonials-card-text2 {
  color: var(--dl-color-gray-700);
}
</style>
